import React, { useRef, useState } from 'react';
import {Button} from '@mbank-design/design-system/components';
import { Box } from '@mbank-design/design-system/components';
import styled from 'styled-components';

function Container(params) {
	const downloadButtonRef = useRef(null);
	const AtagBlue = styled.a`
		color: #184868;
		font-weight: bold;
	`;
    return (
		<Box>
			<Button
				buttonType='secondary' 
				ref={downloadButtonRef}
			>
				<AtagBlue href="https://portal.mfaktoring.pl/" target="_blank">Portal klienta</AtagBlue>
			</Button>
		</Box>
    );
}

export default Container;